/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

const scope = 'audi-cms/App';

// API fetch
export const FETCH_START = `${scope}/FETCH_START`;
export const FETCH_END = `${scope}/FETCH_END`;
export const FETCH_ERROR = `${scope}/FETCH_ERROR`;
export const FETCH_CANCEL = `${scope}/FETCH_CANCEL`;

// Auth
export const USER_LOGIN = `${scope}/USER_LOGIN`;
export const USER_LOGIN_ERROR = `${scope}/USER_LOGIN_ERROR`;
export const USER_LOGIN_SUCCESS = `${scope}/USER_LOGIN_SUCCESS`;
export const USER_CHECK = `${scope}/USER_CHECK`;
export const USER_LOGOUT = `${scope}/USER_LOGOUT`;
export const USER_FORGOT_PASSWORD = `${scope}/USER_FORGOT_PASSWORD`;
export const USER_RESET_PASSWORD = `${scope}/USER_RESET_PASSWORD`;

// User profile
export const LOAD_USER = `${scope}/LOAD_USER`;
export const LOAD_USER_ERROR = `${scope}/LOAD_USER_ERROR`;
export const LOAD_USER_SUCCESS = `${scope}/LOAD_USER_SUCCESS`;
export const LOAD_USER_CANCELLED = `${scope}/LOAD_USER_CANCELLED`;

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = `${scope}/SIDEBAR_VISIBILITY_TOGGLE`;
export const SIDEBAR_VISIBILITY_SHOW = `${scope}/SIDEBAR_VISIBILITY_SHOW`;
export const SIDEBAR_VISIBILITY_HIDE = `${scope}/SIDEBAR_VISIBILITY_HIDE`;
export const SIDEBAR_STICKY_TOGGLE = `${scope}/SIDEBAR_STICKY_TOGGLE`;
export const SIDEBAR_STICKY_ENABLE = `${scope}/SIDEBAR_STICKY_ENABLE`;
export const SIDEBAR_STICKY_DISABLE = `${scope}/SIDEBAR_STICKY_DISABLE`;

// Layout
export const LAYOUT_BOXED_TOGGLE = `${scope}/LAYOUT_BOXED_TOGGLE`;
export const LAYOUT_BOXED_ENABLE = `${scope}/LAYOUT_BOXED_ENABLE`;
export const LAYOUT_BOXED_DISABLE = `${scope}/LAYOUT_BOXED_DISABLE`;

// Theme
export const THEME_TOGGLE = `${scope}/THEME_TOGGLE`;

// User roles
export const ROLE_SUPERADMIN = 'superadmin';
export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';

// Upload
export const USER_UPLOAD = `${scope}/USER_UPLOAD`;
export const USER_UPLOAD_SUCCESS = `${scope}/USER_UPLOAD_SUCCESS`;
export const USER_UPLOAD_ERROR = `${scope}/USER_UPLOAD_ERROR`;

export const ROLES = {
  4: 'Call Centre Agent',
  5: 'Receptionist',
  9: 'Admin',
  2: 'CSA',
  3: 'SA',
  6: 'Foreman',
  7: 'Workshop',
  8: 'Technician',
  11: 'Service Manager',
  // 1: 'Super Admin',
};

export const SA_ROLE = 3;
export const TECHNICIAN_ROLE = 8;
export const FOREMAN_ROLE = 6;
export const CSA_ROLE = 2;
export const CALL_CENTRE_ROLE = 4;
export const RECEPTIONIST = 5;
export const SERVICE_MANAGER = 9; // Actually it is ADMIN ROLE
export const WORKSHOP = 7;
export const SM = 11;

export const SERVICE_CENTER = {
  1: 'Ubi Service Centre',
  2: 'Alexandra Service Centre',
  3: 'Ubi Service Centre (RS Team)',
};

export const LOCATIONS = {
  1: {
    name: 'Ubi',
    id: 1,
  },
  2: {
    name: 'Alexandra Service Centre',
    id: 2,
  },
  3: {
    name: 'Ubi Service Centre (RS Team)',
    id: 3,
  },
};

export const FILE_KEY = {
  STAFFS: 'staffs',
  SETTINGS: 'settings',
  ACCESSORIES: 'accessories',
  MODELS: 'models',
  NOTIFICATIONS: 'notifications',
  PROMOTIONS: 'promotions',
};

export const ACTIVITIES_LOG = {
  LEAVE: 'LEAVE',
  HOLIDAY: 'HOLIDAY',
  CAPACITY: 'CAPACITY',
};
